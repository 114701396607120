import { MARKETPLACES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'
import { SELLER_FEATURES_NAMES } from '@seller/shared/constants/features'

function getRoutes({ marketplace, config }) {
  const headerAndMenuRoutes = [
    {
      name: ROUTES.BO_MERCHANT.HOME,
      path: 'home',
      component: () => dynamicImport(import('./pages/home')),
      meta: {
        autonomousBannerPageName: 'home',
        ...insertIf(config.FF_ENABLE_NEW_SELLER_HOME_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.HOME },
        }),
      },
    },

    {
      name: ROUTES.BO_MERCHANT.REVIEWS,
      path: 'reviews',
      component: () => dynamicImport(import('./pages/customer-reviews')),
      meta: {
        ...insertIf(config.FF_ENABLE_NEW_SELLER_CUSTOMER_REVIEWS_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.CUSTOMER_REVIEWS },
        }),
      },
    },
    {
      name: ROUTES.BO_MERCHANT.INSIGHTS,
      path: 'insights',
      meta: {
        autonomousBannerPageName: 'insights',
        associatedFeature: SELLER_FEATURES_NAMES.INSIGHTS,
        ...insertIf(config.FF_ENABLE_NEW_SELLER_INSIGHTS_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.INSIGHTS },
        }),
      },
      component: () => dynamicImport(import('./pages/insights')),
    },
    {
      path: 'listings',
      name: ROUTES.BO_MERCHANT.LISTINGS.PARENT,
      component: () => dynamicImport(import('./pages/listings')),
      redirect: { name: ROUTES.BO_MERCHANT.LISTINGS.ACTIVE },
      meta: {
        autonomousBannerPageName: 'listings',
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.LISTINGS.ACTIVE,
          path: 'active',
          meta: {
            autonomousBannerPageName: 'listings.active',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.LISTINGS.MISSING_INFOS,
          path: 'missing-infos',
          meta: {
            autonomousBannerPageName: 'listings.missing-infos',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.LISTINGS.IN_VALIDATION,
          path: 'in-validation',
          meta: {
            autonomousBannerPageName: 'listings.in-validation',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.LISTINGS.ARCHIVED,
          path: 'archived',
          meta: {
            autonomousBannerPageName: 'listings.archived',
          },
        },
      ],
    },
    {
      path: 'orders',
      component: () => dynamicImport(import('./pages/orders')),
      redirect: { name: ROUTES.BO_MERCHANT.ORDERS.ALL },
      meta: {
        autonomousBannerPageName: 'orders',
        ...insertIf(config.FF_ENABLE_NEW_SELLER_ORDER_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.ORDERS.INDEX },
        }),
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.ORDERS.ALL,
          path: 'all',
          meta: {
            autonomousBannerPageName: 'orders.all',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.ORDERS.NEW,
          path: 'new',
          meta: {
            autonomousBannerPageName: 'orders.new',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.ORDERS.PENDING,
          path: 'pending',
          meta: {
            autonomousBannerPageName: 'orders.pending',
          },
        },
        {
          name: ROUTES.BO_MERCHANT.ORDERS.NEEDING_RESPONSE,
          path: 'needing-response',
          meta: {
            autonomousBannerPageName: 'orders.needing-response',
          },
        },
      ],
    },
    {
      path: 'opportunities',
      component: () => dynamicImport(import('./pages/opportunities')),
      redirect: { name: ROUTES.BO_MERCHANT.OPPORTUNITIES.PRICING },
      meta: {
        autonomousBannerPageName: 'opportunities',
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.OPPORTUNITIES.PRICING,
          path: 'pricing',
          meta: {
            autonomousBannerPageName: 'opportunities.pricing',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_OPPORTUNITIES_PRICING_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.OPPORTUNITIES.PRICING,
                },
              },
            ),
          },
        },
      ],
    },
    {
      name: ROUTES.BO_MERCHANT.INVOICES.HOME,
      path: 'invoices',
      component: () => dynamicImport(import('./pages/invoices')),
      redirect: {
        name: config.FF_ENABLE_SELLER_WALLET
          ? ROUTES.BO_MERCHANT.INVOICES.WALLET
          : ROUTES.BO_MERCHANT.INVOICES.PENDING,
      },
      meta: {
        autonomousBannerPageName: 'invoices',
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.INVOICES.PENDING,
          path: 'pending',
          meta: {
            autonomousBannerPageName: 'invoices.pending',
          },
          component: () =>
            dynamicImport(import('./pages/invoices/tabs/pending')),
        },
        {
          name: ROUTES.BO_MERCHANT.INVOICES.WALLET,
          path: 'wallet',
          meta: {
            autonomousBannerPageName: 'invoices.wallet',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_INVOICES_WALLET_PAGE, {
              redirectToFrontApps: { name: ROUTES.BO_SELLER.INVOICES.WALLET },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/invoices/tabs/wallet')),
        },
        {
          name: ROUTES.BO_MERCHANT.INVOICES.PAST_INVOICES,
          path: 'past-invoices',
          meta: {
            autonomousBannerPageName: 'invoices.past-invoices',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_INVOICES_PAST_INVOICES_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.INVOICES.PAST_INVOICES,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/invoices/tabs/past-invoices')),
        },
        {
          name: ROUTES.BO_MERCHANT.INVOICES.GOODWILL_GESTURES,
          path: 'goodwill-gestures',
          meta: {
            autonomousBannerPageName: 'invoices.goodwill-gestures',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_INVOICES_GOODWILL_GESTURES_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.INVOICES.GOODWILL_GESTURES,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/invoices/tabs/goodwill-gestures')),
        },
        {
          name: ROUTES.BO_MERCHANT.INVOICES.COMPENSATION,
          path: 'seller-compensation',
          meta: {
            autonomousBannerPageName: 'invoices.seller-compensation',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_INVOICES_SELLER_COMPENSATION_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.INVOICES.SELLER_COMPENSATION,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/invoices/tabs/seller-compensation')),
        },
      ],
    },
    {
      path: 'options',
      component: () => dynamicImport(import('./pages/options')),
      name: ROUTES.BO_MERCHANT.OPTIONS.HOME,
      redirect: {
        name: ROUTES.BO_MERCHANT.OPTIONS.ADDRESSES,
      },
      meta: {
        autonomousBannerPageName: 'options',
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.ADDRESSES,
          path: 'addresses',
          meta: {
            autonomousBannerPageName: 'options.addresses',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_ADDRESSES_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.ADDRESSES,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/addresses')),
        },
        ...insertIf(marketplace === MARKETPLACES.EU, [
          {
            name: ROUTES.BO_MERCHANT.OPTIONS.MARKETS,
            path: 'markets',
            meta: {
              autonomousBannerPageName: 'options.markets',
              ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_MARKETS_PAGE, {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.OPTIONS.MARKETS,
                },
              }),
            },
            component: () =>
              dynamicImport(import('./pages/options/tabs/markets')),
          },
        ]),
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.SHIPPING,
          path: 'shipping',
          meta: {
            autonomousBannerPageName: 'options.shipping',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_SHIPPING_LEGACY_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.SHIPPING,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/shipping')),
        },
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.PAYMENT,
          path: 'payment',
          meta: {
            autonomousBannerPageName: 'options.payment',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_PAYMENT_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.PAYMENT,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/payment')),
        },
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.PRICING,
          path: 'pricing',
          meta: {
            autonomousBannerPageName: 'options.pricing',
            associatedFeature: SELLER_FEATURES_NAMES.PRICING_TOOL,
            ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_PRICING_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.PRICING,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/pricing')),
        },
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.BACKPRICER,
          path: 'backpricer',
          meta: {
            autonomousBannerPageName: 'options.backpricer',
            associatedFeature: SELLER_FEATURES_NAMES.BACKPRICER,
            ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_BACKPRICER_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.BACKPRICER,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/backpricer')),
        },
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.INTEGRATIONS,
          path: 'integrations',
          meta: {
            autonomousBannerPageName: 'options.integrations',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_OPTIONS_INTEGRATIONS_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.OPTIONS.INTEGRATIONS,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/options/tabs/integrations')),
        },
        {
          name: ROUTES.BO_MERCHANT.OPTIONS.BATCH_LISTING_CREATION,
          path: 'batch-listing-creation',
          meta: {
            autonomousBannerPageName: 'options.batch-listing-creation',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_OPTIONS_BATCH_LISTING_CREATION_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.OPTIONS.BATCH_LISTING_CREATION,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(
              import('./pages/options/tabs/batch-listing-creation'),
            ),
        },
      ],
    },
    {
      name: ROUTES.BO_MERCHANT.PROFILE.ROOT,
      path: 'profile',
      component: () => dynamicImport(import('./pages/profile')),
      redirect: { name: ROUTES.BO_MERCHANT.PROFILE.HOME },
      meta: {
        autonomousBannerPageName: 'profile',
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.PROFILE.HOME,
          path: 'home',
          meta: {
            autonomousBannerPageName: 'profile.home',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_PROFILE_HOME_PAGE, {
              redirectToFrontApps: { name: ROUTES.BO_SELLER.PROFILE.HOME },
            }),
          },
          component: () => dynamicImport(import('./pages/profile/tabs/home')),
        },
        {
          name: ROUTES.BO_MERCHANT.PROFILE.COMPANY_INFO,
          path: 'company-info',
          meta: {
            autonomousBannerPageName: 'profile.company-info',
            ...insertIf(config.FF_ENABLE_NEW_SELLER_PROFILE_COMPANY_INFO_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.PROFILE.COMPANY_INFO,
              },
            }),
          },
          component: () =>
            dynamicImport(import('./pages/profile/tabs/company-info')),
        },
        {
          name: ROUTES.BO_MERCHANT.PROFILE.LEGAL_DETAILS,
          path: 'legal-details',
          meta: {
            autonomousBannerPageName: 'profile.legal-details',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_PROFILE_LEGAL_DETAILS_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.PROFILE.LEGAL_DETAILS,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/profile/tabs/legal-details')),
        },
        {
          name: ROUTES.BO_MERCHANT.PROFILE.CONTACT_DETAILS,
          path: 'contact-details',
          meta: {
            autonomousBannerPageName: 'profile.contact-details',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_PROFILE_CONTACT_DETAILS_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.PROFILE.CONTACT_DETAILS,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/profile/tabs/contact-details')),
        },
        {
          name: ROUTES.BO_MERCHANT.PROFILE.USER_PERMISSIONS,
          path: 'user-permissions',
          meta: {
            autonomousBannerPageName: 'profile.user-permissions',
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_PROFILE_USER_PERMISSIONS_PAGE,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.PROFILE.USER_PERMISSIONS,
                },
              },
            ),
          },
          component: () =>
            dynamicImport(import('./pages/profile/tabs/user-permissions')),
        },
      ],
    },
    {
      path: '/bo_merchant/customer-care',
      component: () => dynamicImport(import('./pages/customer-care')),
      redirect: { name: ROUTES.BO_MERCHANT.CUSTOMER_CARE.OPEN_TASKS },
      meta: {
        ...insertIf(config.FF_ENABLE_NEW_SELLER_CUSTOMER_CARE_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.CUSTOMER_CARE.INDEX },
        }),
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.CUSTOMER_CARE.ALL_REQUESTS,
          path: 'all-requests',
          meta: {
            ...insertIf(config.FF_ENABLE_NEW_SELLER_CUSTOMER_CARE_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.CUSTOMER_CARE.ALL_REQUESTS,
              },
            }),
          },
        },
        {
          name: ROUTES.BO_MERCHANT.CUSTOMER_CARE.OPEN_TASKS,
          path: 'open-tasks',
          meta: {
            ...insertIf(config.FF_ENABLE_NEW_SELLER_CUSTOMER_CARE_PAGE, {
              redirectToFrontApps: {
                name: ROUTES.BO_SELLER.CUSTOMER_CARE.OPEN_TASKS,
              },
            }),
          },
        },
      ],
    },
  ]

  const otherRoutes = [
    {
      name: ROUTES.BO_MERCHANT.SHIPPING.CREATE_LABEL,
      path: 'create-shipping-label',
      component: () => dynamicImport(import('./pages/create-shipping-label')),
      meta: {
        autonomousBannerPageName: 'create-shipping-label',
      },
    },
    {
      name: ROUTES.BO_MERCHANT.FEEDBACK,
      path: 'feedback',
      component: () => dynamicImport(import('./pages/feedback')),
      meta: {
        autonomousBannerPageName: 'feedback',
      },
    },
    {
      path: 'international-onboarding',
      component: () =>
        dynamicImport(import('./pages/international-onboarding')),
      redirect: {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.MARKETS,
      },
      meta: {
        autonomousBannerPageName: 'international-onboarding',
        associatedFeature: SELLER_FEATURES_NAMES.INTERNATIONAL_ONBOARDING,
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.MARKETS,
          path: 'markets',
          meta: {
            autonomousBannerPageName: 'international-onboarding.markets',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/markets'),
            ),
        },
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.SHIPPING,
          path: 'shipping',
          meta: {
            autonomousBannerPageName: 'international-onboarding.shipping',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/shipping'),
            ),
        },
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.PAYMENT,
          path: 'payment',
          meta: {
            autonomousBannerPageName: 'international-onboarding.payment',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/payment'),
            ),
        },
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.PRICING_RULES,
          path: 'pricing',
          meta: {
            autonomousBannerPageName: 'international-onboarding.pricing',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/pricing-rules'),
            ),
        },
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.REVIEW,
          path: 'review',
          meta: {
            autonomousBannerPageName: 'international-onboarding.review',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/review'),
            ),
        },
        {
          name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.CONFIRMATION,
          path: 'confirmation',
          meta: {
            autonomousBannerPageName: 'international-onboarding.confirmation',
          },
          component: () =>
            dynamicImport(
              import('./pages/international-onboarding/steps/confirmation'),
            ),
        },
      ],
    },
    {
      path: 'listings/create/:productId',
      name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.DETAILS,
      component: () => dynamicImport(import('./pages/listing-creation')),
      meta: {
        autonomousBannerPageName: 'listing-creation',
      },
    },
    {
      name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.DUPLICATE,
      path: 'duplicate_product/:productBmId',
      meta: { disableClientSideNavigation: true },
    },
    {
      path: 'listings/create',
      name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.SEARCH,
      component: () => dynamicImport(import('./pages/listing-search-products')),
      meta: {
        autonomousBannerPageName: 'listing-search-products',
        ...insertIf(
          config.FF_ENABLE_NEW_SELLER_LISTING_CREATE_PRODUCT_SEARCH_PAGE,
          {
            redirectToFrontApps: {
              name: ROUTES.BO_SELLER.LISTINGS.PRODUCT.SEARCH,
            },
          },
        ),
      },
    },
    {
      name: ROUTES.BO_MERCHANT.NO_ACCESS,
      path: 'no-access',
      component: () => dynamicImport(import('./pages/no-access')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.KYB,
      path: 'options/payment/kyb',
      component: () => dynamicImport(import('./pages/hyperwallet/kyb')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.TRANSFER_METHODS,
      path: 'options/payment/transfer-methods',
      component: () =>
        dynamicImport(import('./pages/hyperwallet/transfer-methods')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.TRANSFER_METHODS_CONFIRMATION,
      path: 'options/payment/transfer-methods/confirmation',
      component: () =>
        dynamicImport(import('./pages/hyperwallet/confirmation')),
    },
    {
      name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.HOME,
      path: 'sourcing-new/orders',
      component: () => dynamicImport(import('./pages/sourcing')),
      redirect: { name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ACTIVE },
      meta: {
        isBuyback: true,
      },
      children: [
        {
          name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ACTIVE,
          path: 'active',
        },
        {
          name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ALL,
          path: 'all',
        },
        {
          name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.PENDING,
          path: 'pending',
        },
      ],
    },
    {
      name: ROUTES.BO_MERCHANT.BUYBACK.ORDER_DETAILS,
      path: 'buyback/orders/:id',
      meta: {
        isBuyback: true,
      },
      component: () =>
        dynamicImport(import('@buyback/refurbisher/orders/pages/OrderDetails')),
    },
    {
      name: ROUTES.BO_MERCHANT.BUYBACK.WISHLIST,
      path: 'buyback/wishlist',
      meta: {
        isBuyback: true,
        ...insertIf(config.FF_ENABLE_NEW_BUYBACK_WISHLIST_PAGE, {
          redirectToFrontApps: { name: ROUTES.BO_SELLER.BUYBACK.WISHLIST },
        }),
      },
      component: () =>
        dynamicImport(import('@buyback/refurbisher/offer/pages/WishList')),
    },
    {
      name: ROUTES.BO_MERCHANT.BUYBACK.CREATE_QUOTATION,
      path: 'buyback/quotations/create/:productId/',
      meta: {
        isBuyback: true,
        ...insertIf(config.FF_ENABLE_NEW_BUYBACK_CREATE_QUOTATION_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_SELLER.BUYBACK.CREATE_QUOTATION,
          },
        }),
      },
      component: () =>
        dynamicImport(
          import('@buyback/refurbisher/offer/pages/CreateProductQuotations'),
        ),
    },
    {
      name: ROUTES.BO_MERCHANT.BUYBACK.SEARCH_PRODUCT,
      path: '/bo_merchant/buyback/products/search',
      meta: {
        isBuyback: true,
        ...insertIf(config.FF_ENABLE_NEW_BUYBACK_SEARCH_PRODUCT_PAGE, {
          redirectToFrontApps: {
            name: ROUTES.BO_SELLER.BUYBACK.SEARCH_PRODUCT,
          },
        }),
      },
      component: () =>
        dynamicImport(import('@buyback/refurbisher/offer/pages/SearchProduct')),
    },
  ]

  return [...headerAndMenuRoutes, ...otherRoutes]
}

export default (routerOptions) => {
  return {
    requiresSellerAuthentication: [
      {
        path: '/',
        component: () => dynamicImport(import('./layouts/Route.vue')),
        meta: {
          seller: {
            isSellerOnboarding: false,
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.ONBOARDING.HOME },
          },
        },
        children: getRoutes(routerOptions),
      },
    ],
    other: [],
  }
}
